import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';


if ($('.attorneys-slider')) {

	const attorneysSlider = new Swiper('.attorneys-slider .swiper', {
		loop: true,
		effect: "creative",
		crossFade: true,
  	creativeEffect: {
		prev: {
          shadow: true,
          translate: ["-20%", 0, -1],
          opacity: 0,
        },
        next: {
          translate: ["100%", 0, 0],
          opacity: 0,
        },
        limitProgress: 1,
        speed: 1000,
      },
	});

	$('.attorneys-slider .next').on('click', function() {
		attorneysSlider.slideNext();
	});

	$('.attorneys-slider .prev').on('click', function() {
		attorneysSlider.slidePrev();
	});
}

