$(function(){

    if ($('body.news-insights').length) {
        const itemTemplate = ({
            url,
            title,
            industry
        }) => `
            <a href="${url}" class="news-insights-item col-span-12 md:col-span-6 h-[417px] px-[3.2rem] py-10 grid content-between">
                <span class="text-green font-miller text-[3.2rem] capitalize italic">${industry}</span>
                <div>
                    <div class="text-white text-[3rem] font-semibold mb-8">${title}</div>
                    <span class="cta font-semibold yellow">Read More&nbsp;+</span>
                </div>
            </a>
        `;

        let doReplace = function(data) {
            let next = $('.next-page');

            $('.item-container').html(data.data.map(itemTemplate).join(''));
            $(next).data('page', data.meta.pagination.current_page);

            if (data.meta.pagination.total_pages === data.meta.pagination.current_page) {
                $(next).hide();
            } else if (data.meta.pagination.total_pages <= 1) {
                $(next).hide();
            } else {
                $(next).show();
            }

            if (data.meta.pagination.total == 0) {
                $('#noResults').show();
            } else {
                $('#noResults').hide();
            }     
        }

        $('.next-page').on('click', function(e) {
            e.preventDefault();
            let _this = this
            let currentPage = $(this).data('page');
            let industry = $('#industry').find(":selected").val();
            let litigation = $('#litigation').find(":selected").val();
            let params = [];
            let searchParams = new URLSearchParams(window.location.search);
            let type = $('.type-link.active').data('type') ? $('.type-link.active').data('type') : null;

            params.push('page=' + (currentPage+1));

            if (searchParams.has('litigation')) {
                params.push('litigation=' + searchParams.get('litigation'));
            } else {
                params.push('litigation=' + litigation);
            }

            if (searchParams.has('industry')) {
                params.push('industry=' + searchParams.get('industry'));
            } else {
                params.push('industry=' + industry);
            }

            if (type) {
                params.push('type=' + type);
            }

            $.getJSON( "/newsInsights.json?" + params.join('&'), function( data ) {

              $('.item-container').append(data.data.map(itemTemplate).join(''));
              $(_this).data('page', data.meta.pagination.current_page);
              
              if (data.meta.pagination.total_pages === data.meta.pagination.current_page) {
                $(_this).hide();
              }
            });
        });

        $('#litigation').change(function(e) {

            e.preventDefault();
            let industry = $('#industry').find(":selected").val();
            let litigation = $(this).find(":selected").val();
            let params = [];
            let searchParams = new URLSearchParams(window.location.search);
            let type = $('.type-link.active').data('type') ? $('.type-link.active').data('type') : null;

            params.push('litigation=' + litigation);

            if (searchParams.has('industry')) {
                params.push('industry=' + searchParams.get('industry'));
            } else {
                params.push('industry=' + industry);
            }

             if (searchParams.has('page')) {
                params.push('page=' + searchParams.get('page'));
            }

            if (type) {
                params.push('type=' + type);
            }

            $.getJSON( "/newsInsights.json?" + params.join('&'), doReplace);

        });


        $('#industry').change(function(e) {

            e.preventDefault();
            let industry = $(this).find(":selected").val();
            let litigation = $('#litigation').find(":selected").val();
            let params = [];
            let searchParams = new URLSearchParams(window.location.search);
            let type = $('.type-link.active').data('type') ? $('.type-link.active').data('type') : null;

            params.push('industry=' + industry);

            if (searchParams.has('litigation')) {
                params.push('litigation=' + searchParams.get('litigation'));
            } else {
                params.push('litigation=' + litigation);
            }

             if (searchParams.has('page')) {
                params.push('page=' + searchParams.get('page'));
            }

            if (type) {
                params.push('type=' + type);
            }

            $.getJSON( "/newsInsights.json?" + params.join('&'), doReplace);

        });

        $('.type-link').on('click', function(e) {
            e.preventDefault();

            let _this = this
            let industry = $('#industry').find(":selected").val();
            let litigation = $('#litigation').find(":selected").val();
            let params = [];
            let searchParams = new URLSearchParams(window.location.search);
            let type = $(this).data('type');

            if (type == "all") {
                $('.type-link').removeClass('active');
                $("#litigation option:selected").prop("selected", false);
                $("#industry option:selected").prop("selected", false);
            } else {
                if ($(_this).hasClass('active')) {
                    // Remove type
                    $('.type-link').removeClass('active');
                } else {
                    params.push('type=' + type);
                    $('.type-link').removeClass('active');
                    $(_this).addClass('active');
                }

                if (searchParams.has('litigation')) {
                    params.push('litigation=' + searchParams.get('litigation'));
                } else {
                    params.push('litigation=' + litigation);
                }

                if (searchParams.has('industry')) {
                    params.push('industry=' + searchParams.get('industry'));
                } else {
                    params.push('industry=' + industry);
                }
            }

            

            $.getJSON( "/newsInsights.json?" + params.join('&'), doReplace);
        });
    }
});