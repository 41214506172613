import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';


if ($('.industries-slider')) {

	const industriesSlider = new Swiper('.industries-slider .swiper', {
		loop: false,
		slidesPerView: 'auto',
		// freeMode: true,
		grabCursor: true,
		spaceBetween: 20,
		pauseOnMouseEnter: true,
		stopOnLastSlide: true,
		speed: 1000,
		autoplay: {
			delay: 2000,
			disableOnInteraction: true,
		},
	});

	$('.industries-slider .next').on('click', function() {
		industriesSlider.slideNext();
	});

	$('.industries-slider .prev').on('click', function() {
		industriesSlider.slidePrev();
	});
}

