import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';


if ($('.results-slider')) {

	const resultsSlider = new Swiper('.results-slider .swiper', {
		loop: true
	});

	$('.results-slider .next').on('click', function() {
		resultsSlider.slideNext();
	});

	$('.results-slider .prev').on('click', function() {
		resultsSlider.slidePrev();
	});
}

