// import Masonry from 'masonry-layout';

$(function(){
	if ($('#masonry').length) {

	    const itemTemplate = ({
	        title,
	        introParagraph,
	        linkUrl,
	        linkText,
	        linkEmpty
	    }) => `
	    	<div class="result" style="position: absolute; left: 50%; top: 0px;">
				<div class="result-content">
					<h3>${title}</h3>
					${introParagraph}
					<p style="display:none">
						<a href="${linkUrl}" data-link="${linkEmpty}" class="cta green">${linkText}</a>
					</p>
				</div>
			</div>
	    `;



		let doAppend = function(data) {
			let next = $('.next-page');
			var $content = data.data.map(itemTemplate).join('');

			$(next).data('page', data.meta.pagination.current_page);

        	$('#masonry').append($content);
        	$('#masonry').masonry('appended', $content);
        	$("[data-link=false]").parent().show();
        	$('#masonry').masonry('layout');
        	$('#masonry').masonry('reloadItems');
        	$('#masonry').masonry({
			  	columnWidth: '.grid-sizer',
				itemSelector: '.result',
				percentPosition: true,
				transitionDuration: 0
			});

			if (data.meta.pagination.total_pages === data.meta.pagination.current_page) {
                $(next).hide();
            } else if (data.meta.pagination.total_pages <= 1) {
                $(next).hide();
            } else {
                $(next).show();
            }
		}



		let doReplace = function(data) {
			let next = $('.next-page');
			var $content = data.data.map(itemTemplate).join('');

			$(next).data('page', data.meta.pagination.current_page);

        	$('#masonry').masonry('remove', $('.result'));
        	$('#masonry').append($content);
        	$('#masonry').masonry('appended', $content);
        	$("[data-link=false]").parent().show();
        	$('#masonry').masonry('layout');
        	$('#masonry').masonry('reloadItems');
        	$('#masonry').masonry({
			  	columnWidth: '.grid-sizer',
				itemSelector: '.result',
				percentPosition: true,
				transitionDuration: 0
			});

			if (data.meta.pagination.total_pages === data.meta.pagination.current_page) {
                $(next).hide();
            } else if (data.meta.pagination.total_pages <= 1) {
                $(next).hide();
            } else {
                $(next).show();
            }

            if (data.meta.pagination.total == 0) {
            	$('#noResults').show();
            } else {
            	$('#noResults').hide();
            }
		}


		// Init
		$('#masonry').masonry({
		  	columnWidth: '.grid-sizer',
			itemSelector: '.result',
			percentPosition: true,
			transitionDuration: 0
		});


	    $('.next-page').on('click', function(e) {
	        e.preventDefault();
	        let _this = this
	        let currentPage = $(this).data('page');
	        let attorney = $('#attorney').find(":selected").val();
	        let industry = $('#industry').find(":selected").val();
	        let litigation = $('#litigation').find(":selected").val();
	        
	        let params = [];
	        let searchParams = new URLSearchParams(window.location.search);


	        if (searchParams.has('industry')) {
	            params.push('industry=' + searchParams.get('industry'));
	        } else {
	            params.push('industry=' + industry);
	        }

	        if (searchParams.has('litigation')) {
	            params.push('litigation=' + searchParams.get('litigation'));
	        } else {
	            params.push('litigation=' + litigation);
	        }

	        if (searchParams.has('attorney')) {
	            params.push('attorney=' + searchParams.get('attorney'));
	        } else {
	            params.push('attorney=' + attorney);
	        }

	        params.push('page=' + (currentPage+1));

	        $.getJSON( "/results.json?" + params.join('&'), doAppend);
	    });

	    $('#attorney').change(function(e) {

	        e.preventDefault();
	        let attorney = $(this).find(":selected").val();
	        let industry = $('#industry').find(":selected").val();
	        let litigation = $('#litigation').find(":selected").val();
	        let params = [];
	        let searchParams = new URLSearchParams(window.location.search);


	        if (searchParams.has('industry')) {
	            params.push('industry=' + searchParams.get('industry'));
	        } else {
	            params.push('industry=' + industry);
	        }

	        if (searchParams.has('litigation')) {
	            params.push('litigation=' + searchParams.get('litigation'));
	        } else {
	            params.push('litigation=' + litigation);
	        }

	        params.push('attorney=' + attorney);

	         if (searchParams.has('page')) {
	            params.push('page=' + searchParams.get('page'));
	        }

	        $.getJSON( "/results.json?" + params.join('&'), doReplace);
	    });

	    $('#litigation').change(function(e) {

	        e.preventDefault();
	        let attorney = $('#attorney').find(":selected").val();
	        let industry = $('#industry').find(":selected").val();
	        let litigation = $(this).find(":selected").val();
	        let params = [];
	        let searchParams = new URLSearchParams(window.location.search);


	        if (searchParams.has('industry')) {
	            params.push('industry=' + searchParams.get('industry'));
	        } else {
	            params.push('industry=' + industry);
	        }

	        params.push('litigation=' + litigation);

	        if (searchParams.has('attorney')) {
	            params.push('attorney=' + searchParams.get('attorney'));
	        } else {
	            params.push('attorney=' + attorney);
	        }

	         if (searchParams.has('page')) {
	            params.push('page=' + searchParams.get('page'));
	        }

	        $.getJSON( "/results.json?" + params.join('&'), doReplace);

	    });


	    $('#industry').change(function(e) {
	        e.preventDefault();
	        let attorney = $('#attorney').find(":selected").val();
	        let industry = $(this).find(":selected").val();
	        let litigation = $('#litigation').find(":selected").val();
	        let params = [];
	        let searchParams = new URLSearchParams(window.location.search);

	        params.push('industry=' + industry);

	        if (searchParams.has('litigation')) {
	            params.push('litigation=' + searchParams.get('litigation'));
	        } else {
	            params.push('litigation=' + litigation);
	        }

	        if (searchParams.has('attorney')) {
	            params.push('attorney=' + searchParams.get('attorney'));
	        } else {
	            params.push('attorney=' + attorney);
	        }

	        if (searchParams.has('page')) {
	            params.push('page=' + searchParams.get('page'));
	        }

	        $.getJSON( "/results.json?" + params.join('&'), doReplace);

	    });

	    $('#viewall').on('click', function(e) {
	        e.preventDefault();
	        
	        let params = [];
	        $('select').prop("selectedIndex", 0).val(); 

	        $.getJSON( "/results.json?" + params.join('&'), doReplace);

	    });
	}
});