import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

const quant0 = document.querySelector(".quant0");
const quant1 = document.querySelector(".quant1");


if (quant0) {

    function animateQuant0() {
        $('.quant0 .quant:not(.animated)').each(function () {
            $(this).prop('Counter',0).animate({
                Counter: $(this).data('number')
            }, {
                duration: 1000,
                easing: 'swing',
                step: function (now) {
                    $(this).text(Math.ceil(now));
                }
            });

            $(this).addClass('animated');
        });
    }

    function animateQuant1() {

        $('.quant1 .quant:not(.animated)').each(function () {
            $(this).prop('Counter',0).animate({
                Counter: $(this).data('number')
            }, {
                duration: 1000,
                easing: 'swing',
                step: function (now) {
                    $(this).text(Math.ceil(now));
                }
            });

            $(this).addClass('animated');
        });
    }

    ScrollTrigger.create({
        trigger:".quant0",
        start:"top bottom",
        onEnter: animateQuant0,
        scrub:1,
        markers:false,
        invalidateOnRefresh: true
    });

    ScrollTrigger.create({
        trigger:".quant1",
        start:"top bottom",
        onEnter: animateQuant1,
        scrub:1,
        markers:false,
        invalidateOnRefresh: true
    });    
}
