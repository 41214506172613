import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';


if ($('.logo-slider')) {

	const attorneysSlider = new Swiper('.logo-slider .swiper', {
		// loop: true,
		// loopAdditionalSlides: 4,
		breakpoints: {
			// when window width is >= 320px
			320: {
				slidesPerView: 2,
				spaceBetween: 20
			},
			// when window width is >= 480px
			480: {
				slidesPerView: 3,
				spaceBetween: 30
			},
			// when window width is >= 640px
			640: {
				slidesPerView: 4,
				spaceBetween: 40
			}
		 }
	});

	$('.logo-slider .next').on('click', function() {
		attorneysSlider.slideNext();
	});

	$('.logo-slider .prev').on('click', function() {
		attorneysSlider.slidePrev();
	});
}

