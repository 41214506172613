$(document).ready(function() {

	// select your header or whatever element you wish
	const header = document.querySelector("#header");

	const hasChildrenElements = document.querySelectorAll('.nav-parent.has-children');

	var delay = 1000;
	setTimeout(function() {

		if ($(window).width() > 1024) {
			$('.nav-dropdown').show();
		} else {
			$('.nav-dropdown').attr('style', '');
		}

		hasChildrenElements.forEach(element => {
			element.addEventListener('mouseenter', () => {
				if ($(window).width() > 1024) {
					header.classList.add('open');
					element.classList.add('open');
				}
			});
			
			element.addEventListener('mouseleave', () => {
				if ($(window).width() > 1024) {
					header.classList.remove('open');
					element.classList.remove('open');
				}
			});

			// element.addEventListener('click', () => {
			// 	if ($(window).width() > 1024) {
			// 		header.classList.remove('open');
			// 		element.classList.remove('open');
			// 	}
			// });
		});
	}, delay);


	// Nav dropdown for attorneys
	$('#nav-attorney-location').change(function(e) {
		let location = $(this).find(":selected").val();
		window.location.href = '/attorneys?location=' + location;
	});


	$('#hamburger').on('click', function() {
		$(this).toggleClass('open');
		$('body, html').toggleClass('no-scroll');
		
	  if (header.classList.contains('open')) {
		header.classList.remove('open');
	  } else {
		header.classList.add('open');
		header.classList.remove('mini');
		header.classList.remove('mini');
	  }
	});

	$(".nav-item.has-children:not('.attorneys-link')").on('click', function(e) {
		if ($(window).width() <= 1024) {
			e.preventDefault();
			let nodeId = $(this).data('node-id');
			if ($(this).hasClass('has-children')) {
				$('#nav-item-' + nodeId).addClass('open');
			}
			$(this).addClass('open');
			$('nav, .icon-links').addClass('hide');
		}
	});

	$('.back').on('click', function(e) {
		if ($(window).width() <= 1024) {
			$('.nav-item.open').removeClass('open');
			$('nav, .icon-links').removeClass('hide');
			setTimeout(function(){
			$('.nav-dropdown.open').removeClass('open');
		}, 300);
		}
	});


	$(window).resize(function() {
		if ($(window).width() > 1024) {
			header.classList.remove('open');
			$('#hamburger').removeClass('open');
			$('.nav-dropdown').show();
			$('body, html').removeClass('no-scroll');
			
		} else {
			$('.nav-dropdown').attr('style', '');
		}
	});
});