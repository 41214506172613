const locationModal = document.querySelectorAll('.location-parent');

if (locationModal.length) {
	locationModal.forEach(element => {
		element.addEventListener('click', (e) => {
			e.preventDefault();
			console.log($('#' + element.dataset.location));
			$('#modal-' + element.dataset.location).css('display', 'block');
		});
	});

	overlay.addEventListener('click', () => {
		$('.location-modal').hide();
	});

	$('.x').on('click', function() {
		$('.location-modal').hide();
	});
};