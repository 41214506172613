$(document).ready(function() {

	$('select').each(function() {
		$(this).change(function () {
			if ($(this).val() == "") {
				$(this).addClass("empty");
			} else { 
				$(this).removeClass("empty");
			}
		});
	})

	$("select").change();


	$(window).scroll(function() {
		if ($(this).scrollTop() > 600) {
			$('#toTopBtn').fadeIn();
		} else {
			$('#toTopBtn').fadeOut();
		}
	});

	$('#toTopBtn').click(function() {
		$("html, body").animate({
			scrollTop: 0
		}, 0);
		return false;
	});

	$('[data-link]').click(function(e) {
		window.location = $(this).data('link');
	});
});