import Swiper from 'swiper/bundle';
// import 'swiper/css/bundle';


if ($('.carousel-slider')) {
	function spaceBetween() {
		return (window.innerWidth * .15);
	};

	const carouselSlider = new Swiper('.carousel-slider .swiper', {
  		// Optional parameters
		loop: true,
		slidesPerView: 'auto',
		centeredSlides: true,
		spaceBetween: spaceBetween(),
		effect : 'coverflow',
		coverflowEffect: {
	        rotate: 0,
	        stretch: 0,
	        depth: 300,
	        modifier: 1,
	        slideShadows: false,
	    },
	    autoplay: {
	        delay: 2500,
	        disableOnInteraction: false,
		},
	});

	$('.carousel-slider .next').on('click', function() {
		carouselSlider.slideNext();
	});

	$('.carousel-slider .prev').on('click', function() {
		carouselSlider.slidePrev();
	});

}